// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "css-vars";
//@import "~ng-zorro-antd/ng-zorro-antd.aliyun.less";
//@import "../node_modules/ng-zorro-antd/ng-zorro-antd.aliyun.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */


// >>> Primary
@primary-color: @app-primary-color;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.child-route-activated {
  display: none;
}
